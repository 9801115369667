import { User } from "@onpreo/database";

export type UserState = Omit<User, "password">;

export const initialUserState = {
    id: "",
    meta: {
        name: "",
        phone: ""
    },
    isOnboarded: false,
    hasReadAgreement: false,
    workspace: {},
    email: "",
    isActivated: false,
    role: "admin"
} as UserState;
