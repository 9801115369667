import type { AppProps } from "next/app";
import Head from "next/head";
import React, { Fragment, useEffect } from "react";
import { ThemeProvider } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { onpreoTheme } from "@onpreo/components/src/theme";
import { initStore } from "../src/store";
import { Provider } from "react-redux";
import { Snacker } from "@onpreo/slices/src/snackbar";

function MyApp({ Component, pageProps }: AppProps) {
    const store = initStore(pageProps.initialReduxState);
    useEffect(() => {
        // Remove the server-side injected CSS.
        const jssStyles = document.querySelector("#jss-server-side");
        if (jssStyles && jssStyles.parentElement) jssStyles.parentElement.removeChild(jssStyles);
    }, []);

    return (
        <Fragment>
            <Head>
                <title>onpreo - Expose Generator</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Head>
            <ThemeProvider theme={onpreoTheme}>
                <Provider store={store}>
                    <Snacker />
                    <CssBaseline />
                    <Component {...pageProps} />
                </Provider>
            </ThemeProvider>
        </Fragment>
    );
}

export default MyApp;
