import { initialSharedState, SharedReduxState } from "@onpreo/slices";
import { initialRealEstateState, RealEstateState } from "./real-estate";
import { initialUserState, UserState } from "./user";

export default interface ReduxState extends SharedReduxState {
    realEstate: RealEstateState;
    user: UserState;
}

export const initialReduxState: ReduxState = {
    realEstate: initialRealEstateState,
    user: initialUserState,
    ...initialSharedState
};
