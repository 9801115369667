import { RealEstate } from "@onpreo/database/src/definitions/real-estate";

type CurrentRealEstateState = RealEstate & { _id?: string };

export type RealEstateState = {
    realEstate: CurrentRealEstateState;
    updateAllWatermarks: boolean;
    docUpdated: boolean;
};

export const initialRealEstateState = {
    realEstate: {
        salesProgress: "AGREE_TO_START_PRICE",
        category: "ETW",
        address: {
            nation: "",
            street: "",
            house_number: "",
            zip: "",
            town: ""
        },
        numberOfRequests: 0,
        askingPrice: {},
        workspace: {},
        user: {}
    },
    updateAllWatermarks: false,
    docUpdated: false
} as RealEstateState;
