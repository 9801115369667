import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialReduxState } from "../models";
import { RealEstateState } from "../models/real-estate";
import { RealEstate } from "@onpreo/database/src/definitions/real-estate";

export const realEstateSlice = createSlice({
    name: "realEstate",
    initialState: initialReduxState.realEstate,
    reducers: {
        setRealEstate: (state, action: PayloadAction<RealEstate>) => {
            // @ts-ignore
            state.realEstate = action.payload;
            return state;
        },
        setWatermarkUpdate: (state, action: PayloadAction<typeof initialReduxState.realEstate.updateAllWatermarks>) => {
            state.updateAllWatermarks = action.payload;
        },
        setDocUpdated: (state, action: PayloadAction<typeof initialReduxState.realEstate.docUpdated>) => {
            state.docUpdated = action.payload;
        }
    }
});

export const { setRealEstate, setWatermarkUpdate, setDocUpdated } = realEstateSlice.actions;

export default realEstateSlice.reducer;
